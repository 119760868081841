<template>
	<div class="section_wrap pt-50">
		<div class="subindex_wrap">
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_mainbox">
						<div class="myphoto_area">
							<div class="myphoto">
								<img
									v-if="item_card.profle_nft_card_img_url"
									:src="$request.upload_url(item_card.profle_nft_card_img_url)" alt="item_card.file.name"
								>
								<img
									v-else
									:src="require('@/assets/image/@noimage.png')" alt="noimage"
								>
							</div>
							<label class="img_set" @click="on1011">
								<span class="hide">이미지첨부</span>
							</label>
						</div>
						<div>
							<div class="nickname">
								<div class="bright_input">
									<label for="nickName">닉네임</label>
									<div class="input_utill">
										<input
											v-model="item_card.nickname"
											type="text" id="nickName" placeholder="닉네임을 설정해주세요" maxlength='10'
											disabled
										>
										<div
											v-show="false"
											class="input_utill_area"
										>
											<div class="btn_del">
												<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
												<button
													:class="{ active: item_card.nickname.length > 0 }"
													@click="item_card.nickname = ''"
												><span class="hide">닫기</span></button>
											</div>
											<span class="input_text_count" v-if="item_card.id">{{ item_card.nick.length }}/10</span>
										</div>
									</div>
								</div>
							</div>
							<div class="status_message">
								<div class="bright_input">
									<label for="statusMessage">상태 메시지</label>
									<div class="input_utill">
										<input
											v-model="item_card.state_message"
											type="text" id="statusMessage" placeholder="상태메시지를 입력해주세요." maxlength='20'>
										<div class="input_utill_area">
											<div class="btn_del">
												<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
												<button
													:class="{ active: item_card.state_message.length > 0 }"
													@click="item_card.state_message = ''"
												><span class="hide">닫기</span></button>
											</div>
											<!-- TODO : 텍스트 수 카운트 필요 -->
											<span class="input_text_count" v-if="item_card.id">{{ item_card.state_message.length }}/20</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_3 interest">
						<form action="">
							<label for="interest">관심사 선택</label>
							<div class="chk_block">
								<input
									v-model="item_card.fav"
									value="CM00400001"
									type="checkbox" id="inter_1" class="inter_music"
								><label for="inter_1">Music</label>
								<input
									v-model="item_card.fav"
									value="CM00400002" type="checkbox" id="inter_2" class="inter_art"
								><label for="inter_2">Art</label>
								<input
									v-model="item_card.fav"
									value="CM00400003" type="checkbox" id="inter_3" class="inter_fashion"
								><label for="inter_3">Fashion</label>
								<input
									v-model="item_card.fav"
									value="CM00400004" type="checkbox" id="inter_4" class="inter_influence"
								><label for="inter_4">Influence</label>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<transition name="slide-fade">
		<mafia1011
			v-if="is_1011"

			:user="user"

			@click="setProfile"
			@cancel="is_1011 = !is_1011"
		></mafia1011>
		</transition>
	</div>
</template>

<script>
import Mafia1011 from "@/view/Mypage/mafia101-1";
export default {
	name: 'mafia101'
	, components: {Mafia1011}
	, props: ['Axios', 'callBack', 'user']
	, data: function(){
		return {
			program: {
				name: '프로필 편집'
				, title: '프로필 편집'
				, not_footer: true
				, type: 'mypage'
				, is_save: true
				, from: 'myPage'
				, callBack: {
					name: 'save'
				}
			}
			, item: {
				nickname: ''
				, state_message: ''
			}
			, is_1011: false
			, item_main_nft: {
				profle_nft_card_img_url: ''
			}
		}
	}
	,methods: {
		getMyInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_my_info
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if(result.success){
					this.item = result.data
					this.item_card.fav = []
					for(let i = 0; i < this.item_card.concern_sphere_list.length; i++){

						if(this.item_card.concern_sphere_list[i].concern_sphere_img_url.indexOf('off') > -1){
							this.item_card.fav[i] = ''
						}else{
							this.item_card.fav[i] = this.item_card.concern_sphere_list[i].concern_sphere_code
						}
					}
					this.is_1011 = false
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message })
				}
			}catch (e) {
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,updateProfile: async function(){

			this.$bus.$off('callBack')
			try{

				this.$bus.$emit('on', true)

				if(this.item_card.fav.length == 0){
					throw '관심분야를 선택하세요'
				}

				let concern_sphere_list = []
				for(let fav of this.item_card.fav ){
					if(fav){
						concern_sphere_list.push({ concern_sphere_code: fav})
					}
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_profile
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.item_main_nft.nft_card_number
						, state_message: this.item_card.state_message
						, concern_sphere_list: concern_sphere_list
					}
					, type: true
				})
				if(result.success){
					this.$bus.$emit('notify',  { type: 'success', message: this.$language.common.success })
					await this.getMyInfo()
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message})
				}
			}catch(e){
				this.$log.console(e)
				this.$bus.$emit('notify',  { type: 'error', message: e})

			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,setFile: function(e){
			this.$log.console(e)
			this.$set(this.item, 'file', e.target.files[0])

			const reader = new FileReader()

			reader.onload = (e) => {
				this.$set(this.item, 'img_src', e.target.result)
			}
			reader.readAsDataURL(e.target.files[0])
		}
		, setProfile: function(item){
			this.item_main_nft = item
			this.item_main_nft.profle_nft_card_img_url = item.nft_card_img_url
			this.item_card.profle_nft_card_img_url = item.nft_card_img_url
			this.is_1011 = false
		}
		, on1011: function(){
			this.is_1011 = true
		}
	}
	,async created() {
		this.$emit('onLoad', this.program)
		await this.getMyInfo()
	}
	,watch:{
		item: {
			deep: true
			, handler: function(){
				this.$bus.$off('callBack')
				this.$bus.$on('callBack', () => {
					this.updateProfile()
				})
			}
		}
	}
}
</script>